import { Rating as RatingType } from "@interface/entities/profile/Rating";
import { BaseAction } from "@interface/common";

export default class Rating {
  public static readonly SET_DATA = "RATING_SET_DATA";
  public static readonly SET_STATUS = "RATING_SET_STATUS";
  public static readonly SET_ERROR = "RATING_SET_ERROR";

  public static setData(data: RatingType): BaseAction<Rating> {
    return {
      type: Rating.SET_DATA,
      data,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Rating.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Rating.SET_ERROR,
    };
  }
}
