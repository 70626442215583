import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  data: null,
  error: "",
};

export default function Rating(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Rating.SET_DATA:
      return { ...state, data: action.data };
    case Action.Rating.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Rating.SET_ERROR:
      return { ...state, error: action.data };
    default:
      return state;
  }
}
